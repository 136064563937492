import React from "react"
import { Route } from "react-router-dom"

const SecuredRoute = ({
    exact,
    path,
    component,
    user,
    loginComponent,
    roles = [],
}) => {
    const loggedIn = user && !!user.token
    if (loggedIn) {
        if (roles.length > 0) {
            const allowed = roles.some((role) => user.roles?.includes(role))
            if (!allowed)
                return (
                    <span className="not-authorized">
                        User is not authorized for this page
                    </span>
                )
        }
        return <Route exact={exact} path={path} component={component} />
    }
    return <Route exact={exact} path={path} component={loginComponent} />
}

export default SecuredRoute
