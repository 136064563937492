import React from "react"
import Menu from "../layout/menu"
import { menuItems } from "../../../shared/routing"
import "./sidebar.scss"

function getAllowedItems(user, items) {
    return items
        .filter((item) => {
            if (item.roles && item.roles.length > 0) {
                const include = item.roles.some((role) =>
                    user.roles?.includes(role),
                )
                if (!include) return false
            }
            return true
        })
        .map((item) => {
            if (item.items) {
                return {
                    ...item,
                    items: getAllowedItems(user, item.items),
                }
            }
            return item
        })
}

export default function Sidebar({ user }) {
    const allowedItems = getAllowedItems(user, menuItems)
    return (
        <nav className="d-none d-md-block bg-light sidebar">
            <div className="sidebar-sticky">
                {(user.token && <Menu items={allowedItems} />) || null}
            </div>
        </nav>
    )
}
